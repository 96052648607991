import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import { json, LinksFunction } from "@remix-run/node";

import "./tailwind.css";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="de">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script
          src="https://cdn.usefathom.com/script.js"
          data-spa="auto"
          data-site="DOUOCHDQ"
          defer
        ></script>
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export const loader = async () => {
  return json({
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN || "",
    },
  });
};

export default function App() {
  const data = useLoaderData<typeof loader>();

  useEffect(() => {
    if (window.ENV && window.ENV.SENTRY_DSN) {
      Sentry.init({
        dsn: window.ENV.SENTRY_DSN,
        tracesSampleRate: 1,

        integrations: [
          Sentry.browserTracingIntegration({
            useEffect,
            useLocation,
            useMatches,
          }),
        ],

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
      });
    } else {
      console.warn("SENTRY_DSN ist nicht definiert");
    }
  }, []);

  return (
    <>
      <Outlet />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(data.ENV)};`,
        }}
      />
    </>
  );
}
